import StorageHelper from '@/helpers/localStorageHelper'
import ApiTranslations from '@/api/translations'

export default {
	name: 'AdminContainer',
	created () {
		ApiTranslations.getByCurrentLanguage(this)
	},
	computed: {
		siteUrl () {
			return process.env.VUE_BASE_URL || 'https://1tn.com/'
		}
	},
	methods: {
		logout() {
			StorageHelper.remove('Token')
			this.$router.push({name: 'Login'})
		}
	}
}
